import "../src/styles/global.css";
import Routes from "./routes";

import api from "./services/api";
import { useQuery } from "react-query";
import { useCallback } from "react";

export default function App() {
  const fetchPortalStatus = useCallback(async () => {
    const { data } = await api.get("/portais/verify");
    return data;
  }, []);

  const { data, isLoading, isError } = useQuery(
    "verify_portal",
    fetchPortalStatus,
    {
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 24,
    }
  );

  if (isLoading) return <Routes />;

  if (isError || !data?.res) return <Routes />;

  if (data.res.isDisabled) {
    return <DisablePage DATA_DESATIVACAO={data.res.DATA_DESATIVACAO} />;
  }

  return <Routes />;
}

const DisablePage = ({ DATA_DESATIVACAO }) => {
  const dataDesativacao = new Date(DATA_DESATIVACAO).toLocaleString("pt-BR", {
    dateStyle: "full",
  });

  return (
    <main className="disable-page">
      <div className="logos">
        <img
          src="/imagens/logo-work.png"
          alt="Workcenter"
          width="150px"
          height="auto"
        />
        <img
          src="/imagens/logo.png"
          alt="LOGO PORTAL"
          width="150px"
          height="auto"
        />
      </div>
      <h1 className="maintenance-title">Site em Manutenção</h1>
      <p className="deactivation-date">{dataDesativacao}</p>
    </main>
  );
};
